import { createRouter, createWebHashHistory,createWebHistory, RouteRecordRaw } from 'vue-router'
import { defaultLocale } from '@/ts/i18n'
import Page from '../views/Page.vue'

const isStagePages = import.meta.env.VITE_STAGEPAGES === 'true'

function setSlugLength(totalSlugs: number): any {
  const routes = [] as any[]
  let path = '/:clientID/:lang/:slug'
  for (let i = 1; i < totalSlugs; i++) {
    path += `/:slug_${i}`
    const route = {
      path: path,
      name: `subroute_${i}`,
      component: Page,
      meta: {
        guest: true
      }
    }
    routes.push(route)
  }
  return routes
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:clientID/',
    redirect: (to) => {
      return { path: `${to.fullPath}/${defaultLocale}` }
    }
  },
  {
    path: '/:clientID/:lang',
    name: 'homepage',
    component: Page,
  },
  {
    path: '/:clientID/:lang/:slug',
    name: 'page',
    component: Page,
  },
  ...setSlugLength(9)
]

const router = createRouter({
  history: !isStagePages ? createWebHistory() : createWebHashHistory(),
  routes,
})

export default router
