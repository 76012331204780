<template>
  <section class="member-demo-module choice-modal-links">
    <div container>
      <div
        grid="row wrap justify-center center">
        <div
          v-if="section.title || section.content"
          column="xs-12 s-12 l-4">
          <div class="intro-content">
            <h2
              v-if="section.title"
              class="h2 _mbs"
            >
              {{ section.title }}
            </h2>
            <div
              v-if="section.content"
              class="copy-wrap">
              <rich-text :html="section.content"></rich-text>
            </div>
          </div>
        </div>
        <div column="xs-12 s-12 l-8">
          <div class="choice-modals">
            <choice-modal 
              v-for="(choice,i) in section.choiceModal"
              :key="i"
              :choice="choice"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  import ChoiceModal from './ChoiceModals/ChoiceModal.vue'
  export default defineComponent({
    name: 'ChoiceModals',
    components: { ChoiceModal },
    props: {
      section: {
        required: true,
        type: Object as () => Boilerplate
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
