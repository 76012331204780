<template>
  <section class="member-demo-module featured-text-with-images">
    <div container>
      <featured-text
        v-if="featuredText.showFeaturedTextBlock"
        :featured-text="featuredText"
        class="_mtm"/>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    Boilerplate,
    FeaturedText as FeaturedTextInterface
  } from '@/types'

  import FeaturedText from '@/components/sections/FeaturedTextWithImages/FeaturedText.vue'

  interface FeaturedTextState extends Boilerplate {
    featuredText: FeaturedTextInterface;
  }

  export default defineComponent({
    name: 'FeaturedText',
    components: { FeaturedText },
    props: {
      section: {
        required: true,
        type: Object as () => FeaturedTextState
      }
    },
    data() {
      return {
        featuredText: this.section.featuredText
      } 
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
