<template>
  <footer>
    <div container>
      <div grid="row wrap center">
        <div
          class="sub-foot-nav"
          column="xs-12 s-12">
          <nav
            v-if="footerContent.socialLinks.length"
            aria-label="footer social nav"
            class="social ">
            <a
              v-for="(link, i) in footerContent.socialLinks"
              :key="i"
              :href="link.url"
              :aria-label="link.alt"
              target="_blank"
            >
              <img
                :alt="link.alt"
                :src="link.iconPath" />
            </a>
          </nav>
          <nav
            v-if="footerContent.navItems.length"
            aria-label="footer sub-nav"
            class="text-links _mts">
            <ul>
              <li
                v-for="(item, i) in footerContent.navItems"
                :key="i">
                <localized-router-link
                  v-if="item.url"
                  :to="item.url"
                  :style="globalTheme.colorBlack"
                >
                  {{ item.label }}
                </localized-router-link>
                <a
                  v-else-if="item.externalUrl"
                  :href="item.externalUrl"
                  target="_blank"
                  :style="globalTheme.colorBlack"
                >{{ item.label }}</a
                >
              </li>
            </ul>
          </nav>
          <div  v-if="footerContent.subFooterRichText">
            <div container>
              <div grid="row wrap center">
                <div column="xs-12 s-12">
                  <div
                    class="footer-message _mts _text-center"
                    v-html="footerContent.subFooterRichText" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { FooterNavigation } from '@/types'
  export default defineComponent({
    props: {
      footerContent: {
        required: true,
        type: Object as () => FooterNavigation
      }
    }
  })
</script>
