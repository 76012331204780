<template>
  <section class="member-demo-module content-with-video">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="section-header h2"
        >
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          class="copy-wrap">
          <rich-text :html="section.content"></rich-text>
        </div>
      </div>

      <div
        v-if="section.buttonLink && section.buttonLink.href"
        class="cta-wrap center-cta _mtm _mbs">
        <app-button-link
          :button="section.buttonLink"
          :style="globalTheme.button"
        />
      </div>

      <div grid="row wrap">
        <div
          v-if="featuredVideo"
          column="xs-12 s-12"
          class="_mts">
          <VideoBlock
            :video="featuredVideo"
            :poster="featuredVideoPoster"
            :description="featuredVideoDescriptionTrack"
            :subtitles="featuredVideoSubtitleTrack"
            :embed="featuredEmbed"
          />
        </div>
        <VideoBlock
          v-for="(videoBlock, j) in subVideosWithContent"
          :key="j"
          :video="videoBlock.video"
          :poster="videoBlock.videoPoster"
          :description="videoBlock.videoDescriptionTrack"
          :subtitles="videoBlock.videoSubtitleTrack"
          :embed="videoBlock.embedUrl"
          :column="section.desktopSubVideoColumnCount === 'double' ? 'xs-12 s-12 m-6' : 'xs-12 s-12 m-6 l-4' "
          class="_mtm"
        >
          <div class="vid-content">
            <p
              v-if="videoBlock.title"
              :style="globalTheme.color"
              class="title">
              {{ videoBlock.title }}
            </p>
            <rich-text
              v-if="videoBlock.content"
              class="content"
              :html="videoBlock.content"
            />
          </div>
        </VideoBlock>
      </div>

      <div
        v-if="resources && resources.length"
        grid="row wrap"
        class="_mtm">
        <div
          v-for="(resource, g) in resources"
          :key="g"
          :column="
            section.desktopResourceColumnCount === 'double'
              ? 'xs-12 s-12 m-6'
              : 'xs-12 s-12'">
          <resource
            :background-as-offwhite="section.backgroundAsOffwhite"
            :resource="resource"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, Resource as ResourceInterface, ContentStackFile, Link } from '@/types'
  import VideoBlock from '@/components/sections/ContentWithVideo/VideoBlock.vue'
  import Resource from '@/components/sections/Shared/Resource.vue'

  interface ContentWithVideo extends Boilerplate {
    featuredVideo?: Record<string, any>;
    featuredVideoPoster?: Record<string, any>;
    featuredVideoDescriptionTrack?: ContentStackFile;
    featuredVideoSubtitleTrack?: ContentStackFile;
    featuredEmbedUrl?: Link;
    desktopSubVideoColumnCount: string,
    subVideosWithContent?: Record<string, any>[];
    desktopResourceColumnCount: string;
    resources?: ResourceInterface[];
  }

  export default defineComponent({
    name: 'ContentWithVideo',
    components: {
      VideoBlock,
      Resource
    },
    props: {
      section: {
        required: true,
        type: Object as () => ContentWithVideo
      }
    },
    data() {
      return {
        featuredVideo: this.section.featuredVideo,
        featuredVideoPoster: this.section.featuredVideoPoster,
        featuredVideoDescriptionTrack: this.section.featuredVideoDescriptionTrack,
        featuredVideoSubtitleTrack: this.section.featuredVideoSubtitleTrack,
        featuredEmbed: this.section.featuredEmbedUrl,
        subVideosWithContent: this.section.subVideosWithContent,
        resources: this.section.resources
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
