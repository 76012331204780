<template>
  <div class="desktop">
    <div container>
      <div grid="row wrap justify-start flex-end">
        <div column="2">
          <logo
            class="height-100"
          /></div>
        <div column="10">
          <div class="log-search-wrap">
            <language-selector class="_mlxs language-selector"/>
            <div
              v-if="loginLinks.length"
              class="subnav-dropdown _mlxs"
              tabindex="0"
              :style="[globalTheme.buttonWhiteBordered]">
              <lock :style="globalTheme.fill"/>
              <span :style="globalTheme.color">{{loginLinksDropdownLabel || 'Login'}}</span>
              <caret-down :style="globalTheme.fill"/>
              <ul class="dropdown">
                <li
                  v-for="(link,i) in loginLinks"
                  :key="i">
                  <a
                    :href="link.href"
                    target="_blank"
                    aria-describedby="leave-page-label"
                    :style="globalTheme.color">{{link.title}}
                    <caret-right :style="globalTheme.fill"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <nav
            id="dropdown-nav"
            aria-label="main desktop"
          >
            <div class="dropdownBackground"></div>
            <ul
              id="primary-nav"
              ref="primaryNav"
              class="primary-nav"
            >
              <template v-for="(item, i) in navItems">
                <li
                  v-if="item.secondTier"
                  :key="`${i}_group`"
                  :class="[item.group, { eyemed: isEyeMed }]"
                  :style="globalTheme.color"
                  class="has-children tier-1 themed-tab"
                >
                  <button
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="nav-label simple">{{item.label}}
                    <caret-down />
                  </button>
                  <div
                    class="secondary-nav"
                    :style="globalTheme.borderColor">
                    <div container>
                      <ul class="column-wrap">
                        <template v-for="(item, j) in item.secondTier">
                          <li
                            v-if="item.externalUrl"
                            :key="`${j}_external`"
                            class="tier-2">
                            <a
                              :href="item.externalUrl"
                              target="_blank"
                              class="nav-column-header"
                              :style="[globalTheme.color, globalTheme.borderColor]">{{item.label}}
                              <caret-right />
                            </a>
                          </li>
                          <li
                            v-else
                            :key="`${j}_internal`"
                            class="tier-2"
                          >
                            <localized-router-link
                              :to="item.url"
                              class="nav-column-header"
                              :style="[globalTheme.color, globalTheme.borderColor]"
                              :protected="item.protected"
                            >
                              {{item.label}}
                              <caret-right />
                            </localized-router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  v-else-if="item.externalUrl"
                  :key="`${i}_external`"
                  class="tier-1 themed-tab"
                  :class="[item.group, { eyemed: isEyeMed }]"
                  :style="globalTheme.color">
                  <a
                    class="top-level-link"
                    :href="item.externalUrl"
                    target="_blank">
                    <p class="nav-label">
                      {{item.label}}
                    </p>
                  </a>
                </li>
                <li
                  v-else
                  :key="`${i}_internal`"
                  :class="[item.group, { eyemed: isEyeMed }]"
                  class="tier-1 themed-tab"
                  :style="globalTheme.color">
                  <localized-router-link
                    class="top-level-link"
                    :to="item.url"
                    :protected="item.protected"
                  >
                    <p class="nav-label">
                      {{item.label}}
                    </p>
                  </localized-router-link>
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import dropdownNav from '@molecules/Navigation/dropdown-navigation'
  import LanguageSelector from '@molecules/LanguageSelector.vue'
  import { defineComponent } from 'vue'

  import Logo from '@atoms/Logo.vue'
  import CaretRight from '@atoms/svg/CaretRight.vue'
  import CaretDown from '@atoms/svg/CaretDown.vue'
  import Lock from '@atoms/svg/Lock.vue'
  import { MainNavigationGroup } from '@/types'

  export default defineComponent({
    name: 'desktopNav' as string,
    components: {
      Logo,
      CaretRight,
      CaretDown,
      LanguageSelector,
      Lock
    },
    props:{
      navItems: {
        type: Array,
        required: false,
        default: () => []
      },
      loginLinksDropdownLabel: {
        type: String,
        required: false
      },
      loginLinks: {
        type: Object as () => {href: string, title: string} [],
        required: true
      }
    },
    mounted() {
      if (!this.navItems.length) return
      if (this.navItems.find((item: any): (MainNavigationGroup|undefined) => {
        return item.secondTier
      })) {
        dropdownNav((this.$refs.primaryNav as HTMLElement)).init()
      }
    },
  })
</script>
