<template>
  <div class="member-demo-module to-top-button">
    <div container>
      <div class="cta-wrap center-cta _ptm _pbl">
        <button
          :style="globalTheme.color"
          class="scroll-to-top-button"
          @click="scrollToTop">
          <chevron-up
            v-if="section.arrowDirection === 'Up'"
            class="_mbxs"/>
          {{ section.buttonContent }}
          <chevron-up
            v-if="section.arrowDirection !== 'Up'"
            class="_mtxs down"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'

  interface scrollToTopProps extends Boilerplate {
    scrollToSectionOverride: Record<string, unknown>[];
  }
  import ChevronUp from '@/components/atoms/svg/ChevronUp.vue'

  function scrollToTop(): void {
    const appTop = document.getElementById('app')
    window.scrollTo(0, 0)
    if (appTop) {
      appTop.tabIndex = 1
      appTop.focus()
      appTop.tabIndex = 0
    }
  }

  export default defineComponent({
    name: 'to-top-button',
    components: {
      ChevronUp
    },
    props: {
      section: {
        required: true,
        type: Object as () => scrollToTopProps
      }
    },
    data() {
      return {
        scrollToModule:
          this.section.scrollToSectionOverride &&
          this.section.scrollToSectionOverride.length
            ? this.section.scrollToSectionOverride[0]
            : null
      }
    },
    methods: {
      scrollToTop(): void {
        if (this.scrollToModule) {
          const scrollToEl = document.getElementById(`em_${this.scrollToModule.uid}`)
          if (scrollToEl) { 
            scrollToEl.scrollIntoView()
            scrollToEl.tabIndex = 1
            scrollToEl.focus()
            scrollToEl.tabIndex = 0
          } else {
            scrollToTop()
          }
        } else {
          scrollToTop()
        }
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    },
  })
</script>
<style lang="scss" scoped>
.scroll-to-top-button {
  display:flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  line-height:1.5;
  @include NexaHeavy;
  @include font-size(14);
  &:hover,&:focus,&:active {
    text-decoration: underline !important;
  } 
  svg {
    width:25px;
    height:auto;
    &.down {
      transform: rotate(180deg) scaleX(-1);
    }
  }
}

</style>
