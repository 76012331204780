<template>
  <section
    aria-label="contact us section callout"
    class="contact-us">
    <div
      v-if="section.mobileImage && section.mobileImage.url"
      class="mobile until-tablet"
    >
      <div class="landscape">
        <img
          :alt="section.mobileImageAlt"
          :src="section.mobileImage.url"
          class="img-land object-fit-poly"
        />
      </div>
    </div>
    <div container>
      <div grid="row wrap stretch">
        <div
          class="remove-mobile-padding"
          column="xs-12 s-12 m-6 l-4">
          <div class="portrait">
            <img
              v-if="section.image && section.image.url"
              :alt="section.imageAlt"
              :src="section.image.url"
              class="img-port object-fit-poly"
            />
          </div>
        </div>
        <div
          class="contact-wrap bg-to-change"
          column="xs-12 s-12 m-6 l-8"
          :style="globalTheme.backgroundColor"
        >
          <div>
            <app-inline-svg
              v-if="section.icon && section.icon.url"
              :src="section.icon.url"
            />
            <h2
              v-if="section.title"
              class="h2">{{ section.title }}</h2>
            <div
              v-if="section.content"
              v-html="section.content"></div>
            <app-button-link
              :button="section.buttonLink"
              class="white"
              :style="globalTheme.buttonWhite"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import { ContactCta } from '@/types'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'contact-cta',
    props: {
      section: {
        type: Object as () => ContactCta,
        required: true
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
