import { SmallCallouts } from '@/types'

export function smallCalloutsFactory(section): SmallCallouts {
    return {
      title: section.sectionTitle,
      content: section.content,
      ariaLabel: section.ariaLabel,
      colorOptions: section.colors,
      callouts: section.smallCallout.map(callout => {
        return {
          iconPath: callout.icon ? callout.icon.url : '',
          title: callout.title,
          content: callout.content,
          buttonCta: callout.buttonLink,
        }
      })
    }
}
