<template>
  <img
    v-if="image && image.url"
    :src="image.url"
  >
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackFile } from '@/types'

  export default defineComponent({
    name: 'app-image',
    props: {
      image: {
        required: true,
        type: Object as () => ContentStackFile
      }
    }
  })
</script>
