<template>
  <Form
    v-slot="{ errors }"
    ref="contactForm"
    action=""
    method="post"
    class="signup-form"
    @submit="onSubmit"
  >
    <div grid="row wrap stretch">
      <div
        v-for="(input, i) in inputs"
        :key="i"
        :column="input.columnCount"
        :style="globalTheme.colorText"
      >
        <form-input :input="input" />
      </div>
      <div
        column="xs-12 s-12 m-6"
        class="submit-wrap _text-center">
        <button
          :style="globalTheme.button"
          class="btn"
          type="submit"
          @click="submissionAttempted = true"
        >
          {{signupFormLabels.submit}}
        </button>
      </div>
      <div
        v-if="Object.keys(errors).length && submissionAttempted"
        column="xs-12 s-12"
      >
        <p
          :style="globalTheme.colorText"
          class="error-message">
          {{signupFormLabels.formErrorMessage}}
        </p>
      </div>
    </div>
  </Form>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import FormInput from '@/components/sections/Hero/FormInput.vue'
  import { Form } from 'vee-validate'
  import { v4 as uuidv4 } from 'uuid'


  const emailLabel = `email_${uuidv4()}`
  const zipLabel = `zip_${uuidv4()}`
  export default defineComponent({
    name: 'SignupForm',
    components: {
      FormInput,
      Form
    },
    props: {
      colorOverride: {
        type: String,
        required: false
      },
      colorTextOverride: {
        type: String,
        required: false
      },
      signupFormLabels: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        inputs: [
          {
            name: emailLabel,
            type: 'email',
            label: this.signupFormLabels.emailAddressLabel,
            columnCount: 'xs-12 s-12 m-12',
            errorMessage: this.signupFormLabels.validEmailAddress
          },
          {
            name: zipLabel,
            type: 'text',
            label: this.signupFormLabels.zipLabel,
            columnCount: 'xs-12 s-12 m-6',
            errorMessage: this.signupFormLabels.validZipCode,
            isZip: true
          }
        ],
        submissionAttempted: false
      }
    },
    methods: {
      onSubmit(values): void {
        window.open(`https://eyemed.memberquotes.com/Quote/LandingPage/EyeMed_1?rn=000000051-207-010&roi=Primary&sessionStorage=2e8d154a-c3c6-47b1-a0e0-4e9a7b63a61d&zc=${values[zipLabel]}&email=${values[emailLabel]}`, '_blank')
      }
    }
  })
</script>
