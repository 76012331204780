const csProxyBaseUrl = import.meta.env.VITE_CS_PROXY_BASE_URL
import axios from 'axios'
import { objectKeysToCamelCase } from '@oodletech/oodle-js/js'
import { createLoopableSections } from './loopable.sections'
import { ContentStackPagePayload } from '@/types'

const timeStamp = `?date=${Date.now()}`

// Preserve initial contentstack function names for easier swap out of imports later (only the import `from` path will need to be updated this way)

export async function getPage(
  stackPayLoad: ContentStackPagePayload
): Promise<any> {
  const dataFromOriginalClientID = await getPageByClientID(stackPayLoad)
  if (dataFromOriginalClientID) {
    return dataFromOriginalClientID
  } else {
    stackPayLoad.clientID = 'eyemed'
    const dataFromFallbackClientID = await getPageByClientID(stackPayLoad)
    if (dataFromFallbackClientID) {
      return dataFromFallbackClientID
    } else {
      return null
    }
  }
}

async function getPageByClientID(
  stackPayLoad: ContentStackPagePayload
): Promise<any> {
  const fullFetchableUrl = `${csProxyBaseUrl}/${stackPayLoad.clientID}/page/${stackPayLoad.pageSlug}/${stackPayLoad.lang}-us/content.json${timeStamp}`
  const data = await axios.get(fullFetchableUrl)
  .then(res => res.data)
  .catch(() => null)
  if (data) {
    return formatPageData(data)
  } else {
    return null
  }
}

function formatPageData(data) {
  const camelDatadata = objectKeysToCamelCase(data)
    const page = { ...camelDatadata }
    page.loopableSections = createLoopableSections(camelDatadata.sections)
    return page
}

export async function getGlobals(
  stackPayLoad: ContentStackPagePayload
): Promise<any> {
  const fullFetchableUrl = `${csProxyBaseUrl}/${stackPayLoad.clientID}/global/${stackPayLoad.lang}-us/content.json${timeStamp}`
  const data = await axios.get(fullFetchableUrl)
  .then(res => res.data)
  .catch(() => null)
  if (data) {
    return objectKeysToCamelCase(data)
  } else {
    return null
  }
}
