import {
  MainNavigationItem,
  MainNavigationItemExternal,
  MainNavigationGroup
} from '@/types'
import { paramCase } from 'change-case'

function removeSlash(url: string): string {
  return url ? url.replace(/^\/|\/$/g, '') : ''
}

export function createInternalLink(
  pageLink,
  title: string
): MainNavigationItem | undefined {
  if (!pageLink) return
  return {
    label: title,
    url: removeSlash(pageLink.url),
    column: pageLink.column || (null as number | null)
  } as MainNavigationItem
}

export function createExternalLink(externalLink): MainNavigationItemExternal {
  return externalLink && externalLink.link
    ? {
        label: externalLink.link.title as string,
        externalUrl: externalLink.link.href as string
      }
    : { label: '' as string, externalUrl: '' as string }
}

export function mainNavigationFactory(
  navItems: any[]
): (MainNavigationItem | MainNavigationItemExternal | MainNavigationGroup)[] {
  const mainNavigation: (
    | MainNavigationItem
    | MainNavigationItemExternal
    | MainNavigationGroup
  )[] = navItems.map(navItem => {
    if (Object.keys(navItem)[0] === 'topLevelNavItem') {
      return createInternalLink(
        navItem.topLevelNavItem.pageLink[0],
        navItem.topLevelNavItem.linkTitle
      ) as MainNavigationItem
    } else if (Object.keys(navItem)[0] === 'topLevelExternalLink') {
      const externalLink = navItem.topLevelExternalLink
      return createExternalLink(externalLink) as MainNavigationItemExternal
    } else {
      const groupedLinks = navItem.category.links || []
      return {
        group: paramCase(navItem.category.category),
        label: navItem.category.category,
        secondTier: groupedLinks.map(link => {
          if (link.internalLink) {
            const internalLink = link.internalLink
            return createInternalLink(
              internalLink.pageLink[0],
              internalLink.linkTitle
            ) as MainNavigationItem
          } else {
            const externalLink = link.externalLink
            return createExternalLink(
              externalLink
            ) as MainNavigationItemExternal
          }
        })
      } as MainNavigationGroup
    }
  })
  return mainNavigation
}
