<template>
  <Field
    :type="input.type"
    :rules="setRule()"
    :name="input.name" 
    v-slot="{ field, errors, errorMessage }">
    <div
      class="form-group"
      :class="[{errors: errors.length }, {'textarea-group': asTextarea}]">
      <label :for="input.name"> {{input.label}} <span v-if="input.required">*</span> {{errorMessage}} </label>
      <textarea
        v-if="asTextarea"
        :id="input.name" 
        v-bind="field"
        type="text">

      </textarea>
      <input
        v-else
        :id="input.name"
        v-bind="field"
        :type="input.type || 'text'" />
    </div>
  </Field>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Field } from 'vee-validate'

  export default defineComponent({
    components: {
      Field
    },
    props: {
      input: {
        required: true,
        type: Object
      },
      asTextarea: {
        type: Boolean,
        default: false
      },
      requiredField: {
        type: String,
        default: 'This field is required'
      } 
    },
    methods: {
      isRequired(value): string|boolean  {
        if (value && value.trim()) {
          return true
        }
        return this.requiredField
      },
      validateFurther(value): string|boolean  {
        const input = this.input
        // if the field is empty
        if (!value) {
          return this.requiredField
        }
        // if the field is not a valid email
        const regex = input.extraValidationRegex
        if (!regex.test(value)) {
          return input.additionalErrorMessage
        }
        // All is good
        return true
      },
      setRule(): ((val) => string|boolean)|null {
        const input = this.input
        if (!input.required) return null
        if (!!input.extraValidationRegex && !!input.additionalErrorMessage) {
          return this.validateFurther
        } else {
          return this.isRequired
        }
      }
    }
  })
</script>
