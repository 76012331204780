<template>
  <div class="horizontal-card">
    <app-inline-svg
      :style="globalTheme.color"
      :src="fileIcon"
      class="svg" />
    <div class="content">
      <h3
        v-if="featuredText.title"
        :style="globalTheme.color"
        class="h4">{{featuredText.title}}</h3>
      <rich-text
        class="callout-content-wrap"
        :html="featuredText.content" />
    </div>
    <app-button-link
      :button="featuredText.buttonLink"
      :style="globalTheme.button"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { FeaturedText } from '@/types'
  import defaultFileIcon from '@/assets/pdf.svg'

  export default defineComponent({
    props: {
      featuredText: {
        type: Object as () => FeaturedText,
        required: true
      }
    },
    data() {
      return {
        defaultFileIcon: defaultFileIcon
      }
    },
    computed: {
      fileIcon(): string {
        const uniqueIcon = this.featuredText.svgIcon
        if (!uniqueIcon) return defaultFileIcon
        return uniqueIcon.url || defaultFileIcon
      }
    }
  })
</script>
