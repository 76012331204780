<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
    class="svg-400x400"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M270.027,61.464C296.216,29.57,339.551,12.948,400,11.587v86.649c-24.18,2.701-40.98,10.76-50.379,24.19
	c-9.41,13.44-14.11,33.59-14.11,60.449v12.09H400v193.447H230.732V207.055C230.732,141.906,243.827,93.379,270.027,61.464"
    ></path>
    <path
      class="st0"
      d="M39.629,61.464c26.2-31.894,69.524-48.516,129.973-49.877v86.649c-24.18,2.701-40.98,10.76-50.379,24.19
	c-9.41,13.44-14.1,33.59-14.1,60.449v12.09h64.479v193.447H0.334V207.055C0.334,141.906,13.429,93.379,39.629,61.464"
    ></path>
  </svg>

</template>
