<template>
  <div
    class="theloop">
    <component
      :is="pascalCase(section.componentName)"
      v-for="(section, i) in loopableSections"
      :id="`em_${section.uid}`"
      :key="section.componentName + i"
      class="loop-section page"
      :class="[
        {'bg-offwhite': section.colorOptions ? section.colorOptions.backgroundAsOffwhite : false}, 
        {'bg-white': section.colorOptions ? !section.colorOptions.backgroundAsOffwhite : true},
      ]"
      :section="section"
      :style="setBackgroundColor(section)"
      :name="pascalCase(section.componentName)"
      :aria-hidden="section.componentName === 'navAnchorBookend' ? 'true' : null"
      :aria-label="setSectionAriaLabel(section, i)"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { pascalCase, noCase } from 'change-case'
  import components from '@/components/loopable-components'
  export default defineComponent({
    components: components,
    props: {
      loaded: {
        type: Boolean,
        default: false
      },
      loopableSections: {
        required: true,
        type: Array as () => any[]
      }
    },
    methods: {
      noCase,
      pascalCase,
      isOdd(num): boolean { return num % 2 === 1 },
      setBackgroundColor(section): {backgroundColor: string} {
        return { backgroundColor: section.colorOptions && section.colorOptions.backgroundAsOffwhite ? '#F8F8F8' : '#fff' }
      },
      setSectionAriaLabel(section: Record<string, any>, i): string | null {
        const componentName = `section ${i + 1} - ${noCase(section.componentName)}`
        const ariaLabel = section.ariaLabel

        if (section.componentName === 'navAnchorBookend') {
          return null
        } else {
          return ariaLabel ? ariaLabel : componentName
        }
      }
    },
    mounted() {
      this.$emit('loop-mounted')
    }
  })
</script>
