<template>
  <svg
    width="25"
    height="14"
    viewBox="0 0 25 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.28 1.05372L1.17261 11.1611C0.847085 11.4867 0.684324 11.861 0.684324 12.2842C0.684324 12.7074 0.847085 13.0654 1.17261 13.3584C1.46557 13.6839 1.82365 13.8467 2.24682 13.8467C2.70255 13.8467 3.0769 13.6839 3.36987 13.3584L12.4031 4.3252L21.4363 13.3584C21.7292 13.6839 22.0873 13.8467 22.5105 13.8467C22.9662 13.8467 23.3406 13.6839 23.6335 13.3584C23.9591 13.0654 24.1218 12.7074 24.1218 12.2842C24.1218 11.861 23.9591 11.4867 23.6335 11.1611L13.5261 1.05372C13.3634 0.890957 13.1843 0.760749 12.989 0.663092C12.7937 0.597988 12.5984 0.565436 12.4031 0.565436C12.2078 0.565436 12.0124 0.597988 11.8171 0.663092C11.6218 0.760749 11.4428 0.890957 11.28 1.05372Z"
      fill="currentColor"/>
  </svg>
</template>
