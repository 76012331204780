<template>
  <div class="collapsible-section">
    <button
      role="button"
      class="bg-white section-header simple"
      :style="globalTheme.color"
      :aria-label="ariaLabel"
      @click.prevent="sectionCollapseToggled"
    >
      <div container>
        <slot name="header-text"></slot>
        <slot name="header-content"></slot>

        <div class="actions">
          <slot name="actions-left"></slot>
        </div>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'toggle-all-collapsible-sections',
    props: {
      ariaLabel: {
        type: String,
        required: false
      }
    },
    methods: {
      sectionCollapseToggled(): void {
        this.$emit('clicked')
      }
    }
  })
</script>
