<template>
  <Field
    v-slot="{ field,errors }"
    :mode="validationMode"
    :name="id"
    :rules="validations"
    class="form-group"
    tag="div"
  >
    <label
      :for="id"
      :class="{'invalid' : errors.length > 0}"
      aria-live="polite"
      @click.prevent
    ><span class="moon">{{ label }}</span>
      <span
        v-if="validations.includes('required')"
        class="asterisk"
        :aria-label="requiredFieldMessage"
      >*</span>
      <span
        v-if="(errors.length > 0)"
        class="lips validation"
      >{{ validationMessage || errors[0] }}</span>
    </label>
    <select
      :id="id"
      v-bind="field"
      :class="{error: errors.length > 0}"
      :disabled="disabled"
      :name="id"
    >
      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option.value"
      >{{ option.label }}</option>
    </select>
  </Field>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Field } from 'vee-validate'
  import { Option } from '@/types'

  export default defineComponent({
    components: {
      Field
    },
    props: {
      value: {
        type: [String,Boolean],
        default: ''
      },
      options: {
        type: Array as () => Option[],
        default: undefined
      },
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      validations: {
        type: String,
        default: ''
      },
      tooltipContent: {
        type: String,
        default: undefined
      },
      validationMode: {
        type: String ,
        default: 'eager'
      },
      validationMessage: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hideOptionLabel: {
        type: Boolean,
        default: false
      },
      noOptions: {
        type: String,
        required: false
      }
    },
  })
</script>
