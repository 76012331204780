<template>
  <div>
    <single-filter
      :section="singleFilterSection"
      :single-filter-categories="singleFilterCategories"
      :single-filter-value="singleFilterValue"
      @singleFilterValueUpdated="setSingleFilterValue"
    />
    <faq-drawers
      v-for="(faqSection, i) in faqSections"
      :key="i"
      :section="faqSection"
      :single-filter-value="singleFilterValue"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import SingleFilter from './FaqSection/SingleFilter.vue'
  import FaqDrawers from './FaqSection/Faqs.vue'
  import { Option, ColorOptionsInterface, Resource as ResourceInterface } from '@/types'

  interface FaqSectionProp {
    colorOptions: ColorOptionsInterface;
    singleFilter: {
      filterLabel: string;
      sectionTitle: string;
    };
    faqSection: {
      category: string;
      resources: ResourceInterface[];
      faqSections: {
        title: string;
        content: string;
        colorOptions?: ColorOptionsInterface;
      }[];
    }[];
  }

  export default defineComponent({
    components: {
      SingleFilter,
      FaqDrawers
    },
    props: {
      section: {
        required: true,
        type: Object as () => FaqSectionProp
      }
    },
    data() {
      return {
        singleFilterValue: '' as string,
        singleFilterSection: this.section.singleFilter,
        faqSections: null as any
      }
    },
    computed: {
      singleFilterCategories(): Option[] {
        const placeHolderOption: Option = {
          value: '',
          label: this.$store.state.globals.globalLabels.viewAll || 'View All'
        }
        const categories: Option[] = [placeHolderOption]
        this.section.faqSection.map(section => {
          if (section.category) {
            if (categories.find(cat => cat.value === section.category)) return // prevent duplicate filter values
            categories.push({
              value: section.category,
              label: section.category
            })
          }
        })
        return categories
      }
    },
    methods: {
      setSingleFilterValue(val): void {
        this.singleFilterValue = val
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    },
    mounted() {
      const faqSections = this.section.faqSection
      faqSections.map(faqSeciton => faqSeciton.faqSections.map((section) => {
        section.colorOptions = this.section.colorOptions
        return section
      }))
      this.faqSections = faqSections
    }
  })
</script>
