import { FooterNavigation, MainNavigationItem, MainNavigationItemExternal } from '@/types'
import { createInternalLink, createExternalLink } from './main-navigation.factory'

export function footerNavigationFactory(microsite): FooterNavigation {
  const socialLinks = microsite.socialLinks
  const footerNavItems = microsite.footerNavItems
  return {
    socialLinks: socialLinks ? socialLinks.map(socialLink => {
      const icon = socialLink.icon
      const link = socialLink.link
      return {
        iconPath: icon ? icon.url : '',
        url: link ? link.href : '',
        alt: link ? link.title : '',
      }
    }) : undefined,
    navItems: footerNavItems ? footerNavItems.map(navItem => {
      if (navItem.internalLink) {
        const link = navItem.internalLink
        return createInternalLink(link.pageLink[0], link.linkTitle) as MainNavigationItem
      } else {
        const externalLink = navItem.externalLink
        const link = externalLink.link
        const totalLink = { ...externalLink, ...link }
        return createExternalLink(totalLink) as MainNavigationItemExternal
      }
    }) : undefined,
    subFooterRichText: microsite.subFooterRichText
  }
}
