<template>
  <section
    v-if="filterConditions"
    class="fg-will-change _p0 faq-section">
    <app-collapsible-section-toggle-all
      v-if="!section.disableAccordionHeader"
      :aria-label="
        isSpanish()
          ? `${
            collapseAll === true ? openLabel : closeLabel
          } todas las regiones de ${section.category} `
          : `${collapseAll === true ? openLabel : closeLabel} all ${
            section.category
          } regions`
      "
      @clicked="openAllDrawers"
    >
      <template v-slot:header-text>
        <div
          class="_m0 h2"
          :style="globalTheme.color">
          {{ section.category }}
        </div>
      </template>
      <template v-slot:actions-left>
        <div
          class="btn --sm white bordered collapse-toggle"
          :style="globalTheme.buttonWhiteBordered"
        >
          {{ collapseAll === true ? openLabel : closeLabel }}
        </div>
      </template>
    </app-collapsible-section-toggle-all>

    <app-collapsible-section
      v-for="(faqSection, i) in section.faqSections"
      :key="i"
      :collapsed="collapseAll"
    >
      <template
        v-slot:header-text
        v-if="faqSection.title">
        <p class="moon">
          {{ faqSection.title }}
        </p>
      </template>
      <app-section class="bg-offwhite simple-copy faq">
        <rich-text
          column="xs-12 s-12"
          :html="faqSection.content"
          :theme-color-override="
            faqSection.colorOptions
              ? faqSection.colorOptions.themeColorOverride
              : null
          "></rich-text>
        <template
          v-slot:below-grid
          v-if="faqSection.resources && faqSection.resources.length"
        >
          <div
            grid="row wrap"
            class="_mtm">
            <div
              v-for="(resource, g) in faqSection.resources"
              :key="g"
              class="_mbs"
              column="xs-12 s-12 m-6"
            >
              <resource
                :resource="resourceFactory(resource)"
                background-as-offwhite/>
            </div>
          </div>
        </template>
      </app-section>
    </app-collapsible-section>
  </section>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import AppCollapsibleSectionToggleAll from '@global/AppCollapsibleSectionToggleAll.vue'
  import { FAQSection } from '@/types'
  import Resource from '@/components/sections/Shared/Resource.vue'
  import { resourceFactory } from '@/ts/factories/resource.factory'

  export default defineComponent({
    components: {
      AppCollapsibleSectionToggleAll,
      Resource
    },
    props: {
      section: {
        required: true,
        type: Object as () => FAQSection
      },
      singleFilterValue: {
        type: String,
        required: false,
        default: ''
      }
    },
    data() {
      return {
        collapseAll: true as boolean
      }
    },
    computed: {
      closeLabel(): string {
        return this.$store.state.globals.globalLabels.close || 'Close'
      },
      openLabel(): string {
        return this.$store.state.globals.globalLabels.open || 'Open'
      },
      filterConditions(): boolean {
        const categoryMatch =
          this.singleFilterValue === '' ||
          this.singleFilterValue === this.section.category
        return categoryMatch
      }
    },
    methods: {
      resourceFactory,
      isSpanish(): boolean {
        return this.$route.params.lang === 'es'
      },
      openAllDrawers(): void {
        this.collapseAll = !this.collapseAll
        if (!this.collapseAll) {
          (this.$el.querySelector(
            '.drawer-toggle'
          ) as HTMLButtonElement)!.focus()
        }
      }
    }
  })
</script>
<style lang="scss" scoped>
.collapse-toggle {
  width: auto;
}
</style>
