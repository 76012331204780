<template>
  <section
    :class="[
      { 'with-strip': section.calloutStrip.length },
      { 'uncontained': !containCard },
      { 'mirrored': section.mirrored },
    ]"
    class="featured-content short hero"
  >
    <div class="hero-page">
      <img
        :alt="shapedProps.heroAltMobile"
        :src="shapedProps.imageMobilePath"
        class="mobile hero-image"
      />
      <img
        v-if="containCard"
        :alt="shapedProps.heroAlt"
        :src="shapedProps.imageDesktopPath"
        class="desktop hero-image"
      />
      <div
        class="content"
        container>
        <div grid="row wrap center">
          <div
            class="content-copy"
            column="xs-12 s-12 m-12 l-6">
            <h1
              v-if="shapedProps.heroTitle"
              class="page-title _mb0 _mt0"
              :style="globalTheme.color"
            >
              {{ shapedProps.heroTitle }}
            </h1>
            <div grid="row wrap">
              <div column="xs-12 s-12 l-12">
                <rich-text
                  v-if="shapedProps.content"
                  class="message"
                  :class="{'_mbs':shapedProps.showSignupForm}"
                  :html="section.content"
                />
              </div>
              <div
                v-if="shapedProps.showSignupForm"
                column="xs-12 s-12 l-12">
                <SignupForm
                 
                  :color-override="buttonColorOverride || colorOverride"
                  :signup-form-labels="shapedProps.signupForm"
                />
                <rich-text
                  v-if="shapedProps.disclaimer"
                  class="disclaimer"
                  :html="shapedProps.disclaimer"
                />
              </div>
            </div>
          </div>
          <div
            v-if="!containCard"
            column
            class="image-wrap">
            <img
              :alt="shapedProps.heroAlt"
              :src="shapedProps.imageDesktopPath"
              class="desktop hero-image"
            />
          </div>
        </div>
      </div>
      <callout-strip
        v-if="calloutStrip"
        class="desktop"
        :section="calloutStrip"
      />
    </div>
    <callout-strip
      v-if="calloutStrip"
      class="mobile"
      :section="calloutStrip" />
  </section>
</template>

<script lang="ts">
  import { Boilerplate } from '@/types'
  import { defineComponent } from 'vue'

  interface Hero extends Boilerplate {
    short: boolean
    heroAlt: string | null | undefined
    heroAltMobile: string | null | undefined
    imageDesktopPath: string
    imageMobilePath: string
    signupForm: Record<string, unknown>[] | undefined
    calloutStrip: Record<string, unknown>[] | undefined
    themeColorOverride: Record<string, unknown>[] | undefined
    textColorOverride: Record<string, unknown>[] | undefined
    buttonColorOverride: Record<string, unknown>[] | undefined
  }

  import SignupForm from './Hero/SignupForm.vue'
  import CalloutStrip from './CalloutStrip.vue'

  export function heroFactory(section): Hero {
    return {
      ...section,
      ...section.signupForm,
      short: section.short,
      heroAlt: section.imageAltDesktop || '',
      heroAltMobile: section.imageAltMobile || '',
      imageDesktopPath: section.imageDesktop ? section.imageDesktop.url : '',
      imageMobilePath: section.imageMobile ? section.imageMobile.url : '',
    }
  }

  export default defineComponent({
    name: 'hero-simple',
    components: { SignupForm, CalloutStrip },
    props: {
      section: {
        type: Object as () => Hero,
        required: true,
      },
    },
    data() {
      return {
        shapedProps: heroFactory(this.section),
        cardWidth: this.section.short
          ? 'xs-12 sm-12 m-12 l-6'
          : 'xs-12 sm-12 m-12 l-5',
        buttonColorOverrideObject:
          this.section.buttonColorOverride &&
          this.section.buttonColorOverride.length
            ? this.section.buttonColorOverride[0]
            : {},
        colorOverrideObject:
          this.section.themeColorOverride &&
          this.section.themeColorOverride.length
            ? this.section.themeColorOverride[0]
            : {},
        textColorOverrideObject:
          this.section.textColorOverride && this.section.textColorOverride.length
            ? this.section.textColorOverride[0]
            : {},
      }
    },
    computed: {
      calloutStrip() {
        const calloutStrip =
          this.section.calloutStrip && this.section.calloutStrip.length
            ? this.section.calloutStrip[0]
            : null
        if (calloutStrip && this.colorOverrideObject) {
          calloutStrip.colorOptions = {
            themeColorOverride: [this.colorOverrideObject],
            colorOverride: this.colorOverrideObject.colorHex,
          }
        }
        return calloutStrip
      },
      buttonColorOverride(): string | undefined {
        return this.buttonColorOverrideObject.colorHex as string | undefined
      },
      colorOverride(): string | undefined {
        return this.colorOverrideObject
          ? (this.colorOverrideObject.colorHex as string)
          : (undefined as string | undefined)
      },
      colorTextOverride(): string | undefined {
        return this.textColorOverrideObject
          ? (this.textColorOverrideObject.colorHex as string)
          : (undefined as string | undefined)
      },
      containCard(): boolean {
        return (
          !!this.section.signupForm &&
          (this.section.signupForm as any).contained !== false
        )
      },
    },
    provide() {
      return {
        colorOptions: {
          backgroundAsOffwhite: false,
          themeColorOverride: [this.section.themeColorOverride],
          useSecondaryColor: false,
        },
      }
    },
  })
</script>
