<template>
  <section class="member-demo-module callout-strip-section">
    <div container>
      <div
        class="callout-strip"
        :style="globalTheme.backgroundColor">
        <div grid="row wrap start">
          <div column="xs-12 s-12 l-4">
            <h2
              v-if="section.sectionTitle"
              :style="globalTheme.colorWhite"
              class="title"
            >
              {{ section.sectionTitle }}
            </h2>
          </div>

          <div column="xs-12 s-12 l-8">
            <div
              grid="row wrap stretch justify-between"
              class="callout-grid">
              <div
                v-for="(callout, i) in section.callout"
                :key="i"
                column="xs-12 s-12 m-3"
                class="_text-center callout"
              >
                <app-inline-svg
                  v-if="callout.icon && callout.icon.url"
                  :src="callout.icon.url"
                  :style="[globalTheme.fillWhite, {height: `${callout.iconWidthOverride}px`}]"
                  class="_mbs"
                />
                <rich-text
                  :style="globalTheme.colorWhite"
                  :html="callout.content"/>
              </div>
            </div>
          </div>
        </div>
        <div
          class="background"
          :style="globalTheme.backgroundColor"/>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  export default defineComponent({
    name: 'CalloutStrip',
    props: {
      section: {
        required: true,
        type: Object as () => Boilerplate
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
