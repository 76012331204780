<template>
  <Field
    :type="input.type"
    :rules="setRule"
    :name="input.name"
    v-slot="{ field, errors }"
  >
    <div
      class="form-group"
      :class="[{ errors: errors.length }]">
      <label :for="input.name">
        {{ input.label }} <span>*</span>
        <template v-if="errors.length">{{
          input.errorMessage
        }}</template></label
      >
      <input
        :id="input.name"
        v-bind="field"
        type="text" />
    </div>
  </Field>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Field } from 'vee-validate'
  export default defineComponent({
    components: {
      Field
    },
    props: {
      input: {
        required: true,
        type: Object
      },
      asTextarea: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      isRequired(value): boolean {
        if (value && value.trim()) {
          return true
        }
        return false
      },
      validateEmail(value): boolean {
        // if the field is empty
        if (!value) {
          return false
        }
        // if the field is not a valid email
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        if (!regex.test(value)) {
          return false
        }
        // All is good
        return true
      },
      validateZip(value): boolean {
        // if the field is empty
        if (!value) {
          return false
        }
        // if the field is not a valid zip
        const regex = /^[0-9]{5}(-[0-9]{4})?$/i
        if (regex.test(value) ) {
          return true
        }
        // All is good
        return false
      },
      setRule(value) {
        if (this.input.type === 'email') {
          return this.validateEmail(value)
        } else if (this.input.isZip) {
          return this.validateZip(value)
        } else {
          return this.isRequired(value)
        } 
      }
    }
  })
</script>
