<template>
  <four-o-four v-if="!page && loaded" />
  <div v-else-if="loaded">
    <div
      id="leave-page-label"
      aria-hidden="true"
      class="sr-only">
      Opens in new window
    </div>
    <app-header
      :nav-items="mainNavItems"
      :login-links="loginLinks"
      :login-links-dropdown-label="loginLinksDropdownLabel"
    />
    <div
      id="sub-nav-breadcrumb"
      class="sub-nav-breadcrumb no-crumbs"
      :style="globalTheme.backgroundColor"
    ></div>
    <div id="app-body">
      <the-loop
        v-if="page"
        :loaded="loaded"
        :loopable-sections="page.loopableSections"
      />
    </div>
    <app-footer
      v-if="footerContent"
      :footer-content="footerContent" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TheLoop from '@/components/TheLoop.vue'
  import AppHeader from '@/components/organisms/Header.vue'
  import AppFooter from '@/components/organisms/Footer.vue'
  import FourOFour from '@/views/404.vue'

  import {
    ContentStackPagePayload,
    MainNavigationItem,
    MainNavigationGroup,
    MainNavigationItemExternal,
    FooterNavigation
  } from '@/types'

  import { getPage, getGlobals } from '@/ts/contentstack.proxy'
  // import { getPage, getGlobals } from '@/ts/contentstack'
  import { mainNavigationFactory } from '@/ts/factories/main-navigation.factory'
  import { footerNavigationFactory } from '@/ts/factories/footer-navigation.factory'
  import { AdobeAnalytics } from '@/mixins/AdobeAnalytics'

  export default defineComponent({
    name: 'Page',
    components: {
      FourOFour,
      TheLoop,
      AppHeader,
      AppFooter
    },
    mixins: [ AdobeAnalytics ],
    data() {
      return {
        page: null as Record<string, any> | null,
        contactInformation: null as Record<string, any> | null,
        loaded: false,
        mainNavItems: null as
          | (
            | MainNavigationItem
            | MainNavigationGroup
            | MainNavigationItemExternal
          )[]
          | null,
        loginLinks: [] as { href: string; title: string }[],
        loginLinksDropdownLabel: '' as string,
        footerContent: null as null | FooterNavigation
      }
    },
    computed: {
      stateCheck() {
        return this.$store.state
      }
    },
    methods: {
      convertFaSvgArias(): void {
        const faSvgs = this.$el.querySelectorAll('.svg-inline--fa')
        faSvgs.forEach(element => {
          element.setAttribute('role', 'presentation')
        })
      },
      setContentStackPayload(): ContentStackPagePayload {
        const currentLang = this.$route.params.lang as string
        const currentClientID = this.$route.params.clientID as string
        const pageSlug =
          `${
            this.$route.fullPath
              .replace(`/${this.$route.params.clientID}`, '')
              .replace(`/${this.$route.params.lang}`, '')
              .split('?')[0]
          }` || ('/' as string)

        return {
          lang: currentLang,
          clientID: currentClientID,
          pageSlug: pageSlug,
          uid: ''
        }
      }
    },
    async created() {
      const currentLang = this.$route.params.lang as string
      const currentClientID = this.$route.params.clientID as string
      const stackPayload = this.setContentStackPayload()

      const vuexGlobals = this.$store.state.globals
      const storedLocale = vuexGlobals ? vuexGlobals.locale : ''
      let globals, globalsUID
      if (
        !vuexGlobals ||
        vuexGlobals.clientId !== currentClientID ||
        storedLocale.substring(0, 2) !== currentLang
      ) {
        globals = await getGlobals(stackPayload)
        if (globals) {
          globalsUID = globals.uid
          this.$store.commit('setCurrentGlobals', globals)
        } else {
          this.loaded = true
          return
        }
      } else {
        globals = vuexGlobals
        globalsUID = vuexGlobals.uid
      }

      this.mainNavItems = mainNavigationFactory(globals.mainNavItems)
      this.loginLinksDropdownLabel = globals.loginDropdownLinks
        ? globals.loginDropdownLinks.loginLinksDropdownLabel
        : ''
      this.loginLinks = globals.loginDropdownLinks
        ? globals.loginDropdownLinks.loginLink
        : []
      this.footerContent = footerNavigationFactory(globals)

      stackPayload.uid = globalsUID
      const page = await getPage(stackPayload)
      this.page = page
      this.loaded = true
      if (page) {
        this.$store.commit('setPrimaryColor', globals.primaryColor)
        this.$store.commit('setSecondaryColor', globals.secondaryColor)
        this.$store.commit('setCurrentRoute', this.$route.path)
        this.$store.commit('setCurrentPage', page)
        this.sendItToAdobe(page.title)
      }
    }
  })
</script>
