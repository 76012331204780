<template>
  <app-section
    grid="center align-center"
    class="single-filter"
  >
    <div
      v-if="section.sectionTitle"
      column="xs-12 s-12 m-6">
      <h2
        class="h2"
        :style="globalTheme.color">{{ section.sectionTitle }}</h2>
    </div>
    <div column="xs-12 s-12 m-6">
      <form-select
        :id="paramCase(section.sectionTitle)"
        v-model="value"
        class="_mb0"
        :label="section.filterLabel"
        :options="singleFilterCategories"
      />
    </div>
  </app-section>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import FormSelect from '@molecules/Form/FormSelect.vue'
  import { Option } from '@/types'
  import { paramCase } from 'change-case'
  interface SingleFilterLabels {
    filterLabel: string;
    title: string;
  }
  export default defineComponent({
    components: {
      FormSelect
    },
    props: {
      section: {
        required: true,
        type: Object as () => SingleFilterLabels
      },
      singleFilterCategories: {
        required: true,
        type: Array as () => Option[]
      },
      singleFilterValue: {
        type: String as () => string,
        default: ''
      },
    },
    data() {
      return {
        value: ''
      }
    },
    watch: {
      singleFilterCategories: function() {
        this.value = ''
      },
      value: function(val) {
        this.$emit('singleFilterValueUpdated', val)
      }
    },
    methods: {
      paramCase
    }
  })
</script>
<style lang="scss">
.single-filter {
  .form-group {
    margin-bottom: 0;
  }
}
</style>
