<template>
  <div
    id="mobile-main-nav"
    ref="mobileNav"
    class="mobile">
    <div class="header">
      <button
        id="menu-toggle"
        class="simple"
        style="order:2;">
        <img
          id="menu-icon"
          :src="iconMenu"
          alt="menu toggle"
        />
      </button>
      <logo
        class="height-100"
        insurance="aetna" />
      <button
        id="mobile-search-toggle"
        :style="[globalTheme.backgroundColor, globalTheme.borderColor]"
        style="display:none"
        class="toggle">
        <img
          src="@/assets/images/svg/icon-ui-search.svg"
          alt="search icon"
        />
      </button>
    </div>
    <div
      id="mobile-search-bar"
      class="search-wrap">
      <close-icon />
    </div>
    <nav aria-label="main">
      <ul
        id="main-accordian-menu"
        class="cd-accordion-menu">
        <template v-for="(item, i) in navItems">
          <li
            v-if="item.secondTier"
            :key="`${i}_category`"
            :style="`order:${(item.mobileOrder + 1) * 2};`"
            class="has-children tier-1"
          >
            <input
              :id="item.group"
              :name="`group-${item.group}`"
              type="checkbox"
            />
            <label
              :for="item.group"
            >{{ item.label }}
              <caret-right />
            </label>
            <ul>
              <li
                v-for="(item, i) in item.secondTier"
                :key="i"
                class="has-children tier-2"
                :style="globalTheme.backgroundColor"
              >
                <a 
                  v-if="item.externalUrl"
                  :href="item.externalUrl"
                  target="_blank"
                  class="sub-nav-item">
                  {{item.label}}
                </a>
                <localized-router-link
                  v-else
                  class="sub-nav-item"
                  :to="item.url"
                  tag="a"
                  :protected="item.protected"
                >
                  {{ item.label }}
                </localized-router-link>
              </li>
            </ul>
          </li>
          <li 
            v-else-if="item.externalUrl"
            :key="`${i}_external`"
            :style="`order:${(item.mobileOrder + 1) * 2};`">
            <a
              class="tier-1"
              :href="item.externalUrl"
              target="_blank">
              <label>
                {{item.label}}
              </label>
            </a>
          </li>
          <li 
            v-else
            :key="`${i}_local`"
            :style="`order:${(item.mobileOrder + 1) * 2};`" >
            <localized-router-link
              class="tier-1"
              :to="item.url"
              :protected="item.protected"
            >
              <label>{{ item.label }}</label>
            </localized-router-link>
          </li>
        </template>
        <li
          v-if="loginLinks.length"
          style="order:99;"
          class="has-children tier-1">
          <input
            id="support-items"
            name="support-items"
            type="checkbox" />
          <label
            for="support-items"
          >{{loginLinksDropdownLabel || 'Login'}}
            <caret-right />
          </label>
          <ul>
            <li
              v-for="(link,i) in loginLinks"
              :key="i"
              class="has-children tier-2"
              :style="globalTheme.backgroundColor">
              <a
                class="sub-nav-item"
                :href="link.href"
                target="blank">{{link.title}}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <language-selector class="_pl0 language-selector mobile"/>
    </nav>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import mobileNav from '@molecules/Navigation/mobile-navigation'
  import LanguageSelector from '@molecules/LanguageSelector.vue'
  import CaretRight from '@atoms/svg/CaretRight.vue'
  import CloseIcon from '@atoms/svg/CloseIcon.vue'
  import Logo from '@atoms/Logo.vue'
  import iconMenu from '@images/svg/icon-ui-menu.svg'
 

  export default defineComponent({
    name: 'mobileNav' as string,
    components: {
      CloseIcon,
      CaretRight,
      Logo,
      LanguageSelector
    },
    props: {
      navItems: {
        type: Array,
        required: true
      },
      loginLinksDropdownLabel: {
        type: String,
        required: false
      },
      loginLinks: {
        type: Object as () => {href: string, title: string} [],
        required: true
      }
    },
    data() {
      return {
        iconMenu: iconMenu as string
      }
    },
    mounted() {
      mobileNav(this.$refs.mobileNav as HTMLElement).init()
    }
  })
</script>
<style scoped>
.language-selector,
label {
  margin-left: 1rem;
}
label {
  width: 100%;
}
.language-selector{
  margin-left: .5rem;
}
</style>
