<template>
  <section class="member-demo-module contact-form-section">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="section-header h2"
        >
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          class="copy-wrap">
          <rich-text :html="section.content"></rich-text>
        </div>
      </div>
      <Form
        v-slot="{ submitForm, errors }"
        ref="contactForm"
        action=""
        method="post"
        class="contact-form"
        @submit="submitForm"
      >
        <div grid="row wrap stretch">
          <div
            v-for="(input, i) in inputs"
            :key="i"
            :column="input.column ? input.column : 'xs-12 s-12 m-6'"
          >
            <form-input
              :input="input"
              :required-field="formFieldLabels.requiredField"
            />
          </div>
          <div column="xs-12 s-12">
            <form-input
              as-textarea
              :required-field="formFieldLabels.requiredField"
              :input="textarea" />
          </div>
          <div column="xs-12 s-12">
            <Field name="file">
              <div
                class="form-group file-upload-group"
                :class="{'files-uploaded': files && files.length}">
                <label for="file-upload">
                  <span class="faux-label">Upload File(s) (.pdf, .doc, .docx, .txt)</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    multiple="multiple"
                    accept=".pdf,.doc,.docx,.txt"
                    @change="handleFileUpload"
                  />
                  <span
                    v-for="(file, f) in files"
                    :key="f"
                    :style="globalTheme.color"
                    class="faux-label">{{file.name}}</span>
                </label>
              </div>
            </Field>
          </div>
          <div
            column="xs-12 s-12"
            class="submit-wrap _text-center">
            <p
              v-if="Object.keys(errors).length && submissionAttempted"
              class="error-message"
            >
              {{ formFieldLabels.formValidationMessage }}
            </p>
            <button
              :style="globalTheme.button"
              class="btn"
              type="submit"
              @click="submissionAttempted = true"
            >
              {{ formFieldLabels.submit }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import FormInput from '@/components/sections/ContactForm/FormInput.vue'
  import { Form, Field } from 'vee-validate'

  import { Boilerplate } from '@/types'
  interface ContactFormProps extends Boilerplate {
    formFieldLabels: {
      email: string
      firstName: string
      lastName: string
      phoneNumber: string
      requiredField: string
      userMessage: string
      formValidationMessage: string
      submit: string
      validEmail: string
    }
  }

  export default defineComponent({
    name: 'ContactForm',
    components: {
      FormInput,
      Form,
      Field
    },
    props: {
      section: {
        required: true,
        type: Object as () => ContactFormProps,
      },
    },
    data() {
      return {
        formFieldLabels: this.section.formFieldLabels || {},
        inputs: [
          {
            name: 'first-name',
            type: 'text',
            label: 'First Name',
            required: true,
          },
          {
            name: 'last-name',
            type: 'text',
            label: 'Last Name',
            required: true,
          },
          {
            name: 'email',
            type: 'email',
            label: 'Email',
            required: true,
            extraValidationRegex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            additionalErrorMessage: this.section.formFieldLabels.validEmail,
          },
          { name: 'phone', type: 'tel', label: 'Phone Number' },
          {
            name: 'city',
            type: 'text',
            label: 'City',
            required: true,
            column: 'xs-12 s-12 m-4',
          },
          {
            name: 'state',
            type: 'text',
            label: 'State',
            required: true,
            column: 'xs-12 s-12 m-4',
          },
          {
            name: 'Zip',
            type: 'text',
            label: 'Zip',
            required: true,
            column: 'xs-12 s-12 m-4',
          },
        ],
        textarea: {
          name: 'comments',
          type: 'text',
          label: 'Comments',
          required: true,
        },
        submissionAttempted: false,
        files: [] as FileList|[]
      }
    },
    methods: {
      handleFileUpload($event: Event): void {
        const target = $event.target as HTMLInputElement
        if (target && target.files ) {
          this.files = target.files as FileList
        }
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions,
      }
    },
  })
</script>
