// Factories
import { smallCalloutsFactory } from '@/ts/factories/smallcallouts.factory'

export function defaultFactory(section: Record<string, any> ): Record<string, any> {
  const colorOptions = section.colorOptions || section.colors
  if (colorOptions && colorOptions.themeColorOverride && colorOptions.themeColorOverride.length) {
    colorOptions.colorOverride = colorOptions.themeColorOverride[0].colorHex
  }
  section.colorOptions = colorOptions
  return section
}

function smallCalloutsWithColorsSet(section: Record<string, any> ) {
  return smallCalloutsFactory(defaultFactory(section))
}

export default function(name: string): any {
  switch (name) {
    case 'smallCalloutsWithImage':
      return smallCalloutsWithColorsSet
    default:
      return defaultFactory
  }
}
