<template>
  <section
    aria-label="featured content"
    class="featured-content fg-will-change no-hero"
  >
    <div
      class="page-sub-header"
      container
    >
      <div grid="row wrap">
        <div column="xs-12 s-12 l-12">
          <template
            v-if="section.simpleTitle">
            <h1
              v-if="section.titleAsHtml"
              class="h1"
              :class="{'no-content': !section.content.length || section.stretchTitle}"
              :style="globalTheme.color"
              v-html="section.simpleTitle"></h1>
            <h1
              v-else
              class="h1"
              :class="{'no-content': !section.content.length || section.stretchTitle}"
              :style="globalTheme.color"
            >{{ section.simpleTitle }}</h1>
          </template>
          <rich-text :html="section.content" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { SimpleTitle } from '@/types'

  export default defineComponent({
    name: 'simpleTitle' as string,
    props: {
      section: {
        type: Object as () => SimpleTitle,
        required: true
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
