<template><svg
  width="14"
  height="25"
  viewBox="0 0 14 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1.05372 13.8105L11.1611 23.918C11.4867 24.2435 11.861 24.4062 12.2842 24.4062C12.7074 24.4062 13.0654 24.2435 13.3584 23.918C13.6839 23.625 13.8467 23.2669 13.8467 22.8438C13.8467 22.388 13.6839 22.0137 13.3584 21.7207L4.3252 12.6875L13.3584 3.6543C13.6839 3.36133 13.8467 3.00326 13.8467 2.58008C13.8467 2.12435 13.6839 1.75 13.3584 1.45703C13.0654 1.13151 12.7074 0.96875 12.2842 0.96875C11.861 0.96875 11.4867 1.13151 11.1611 1.45703L1.05372 11.5645C0.890958 11.7272 0.76075 11.9062 0.663094 12.1016C0.597989 12.2969 0.565437 12.4922 0.565437 12.6875C0.565437 12.8828 0.597989 13.0781 0.663094 13.2734C0.76075 13.4688 0.890958 13.6478 1.05372 13.8105Z"
    fill="currentColor"/>
</svg>
</template>
