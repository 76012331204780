<template>
  <div>
    <slot></slot>
    <div
      class="video-content-wrap vid-embed-16x9 youtube-embed vimeo-embed"
      :style="backgroundImage">
      <button
        v-if="(!playbackBegun && posterExists)"
        class="play-button"
        @click="play">
        <span class="sr-only">play video</span>
        <font-awesome-icon
          :style="{ color: 'white' }"
          aria-hidden="true"
          icon="play"
        />
      </button>
      <video
        v-if="video && video.url"
        ref="video"
        :controls="playbackBegun || !posterExists"
        :poster="poster ? poster.url : null"
        crossorigin="anonymous"
        @click="play"
      >
        <source :src="video.url"/>
        <track
          v-if="subtitles"
          :src="subtitles.url"
          label="English Captions"
          kind="captions"
          srclang="en-us"
        />
        <track
          v-if="description"
          :src="description.url"
          label="English Description"
          kind="descriptions"
          srclang="en-us"
        />
      </video>
      <div
        v-else-if="embed && embed.href">
        <iframe
          v-if="!posterExists || playbackBegun"
          :src="embed.href  + embedQueryParams" 
          allow="autoplay; encrypted-media;"
          allowfullscreen
          :title="embed.title">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackFile, Link } from '@/types'
  
  export default defineComponent({
    name: 'VideoBlock',
    props: {
      video: {
        required: false,
        type: Object as () => ContentStackFile
      },
      poster: {
        type: Object as () => ContentStackFile
      },
      subtitles: {
        type: Object as () => ContentStackFile,
        required: false
      },
      description: {
        type: Object as () => ContentStackFile,
        required: false
      },
      embed: {
        type: Object as () => Link,
        required: false
      }
    },
    data() {
      return {
        playbackBegun: false,
        videoFromFileUploadExists: (this.video && this.video.url) !== null,
        posterExists: (this.poster && this.poster.url) !== null,
      }
    },
    computed: {
      embedQueryParams(): string {return this.posterExists ? '?autoplay=1' : ''}, 
      backgroundImage(): {backgroundImage: string} | null {
        if (this.video && this.video.url) {
          return null
        } else {
          if (this.poster && this.poster.url) {
            return { backgroundImage: `url(${this.poster.url})` }
          }
        }
        return null
      }
    },
    methods: {
      play(): void {
        this.playbackBegun = true
        const videoEl = this.$refs.video as HTMLVideoElement
        const isVideoPlaying = (video: HTMLVideoElement) =>
          !!(
            video.currentTime > 0 &&
            !video.paused &&
            !video.ended &&
            video.readyState > 2
          )
        if (!isVideoPlaying(videoEl)) {
          videoEl.play()
        }
      }
    }
  })
</script>
<style lang="scss" scoped>
video {
  width: 100%;
}
.video-content-wrap {
  position: relative;
  background-size: contain;
  background-position: center center;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: 1;
}
.svg-inline--fa {
  height: 18%;
  width: 18%;
}


</style>
