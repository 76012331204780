<template>
  <section class="member-demo-module logo-slider">
    <div container>
       <agile :options="carouselConfig">
        <div
          v-for="(slide, i) in section.slides"
          :key="i"
          class="slide">
          <div class="slide-content _pm">
            <img
              v-if="slide.logoIcon && slide.logoIcon.url"             
              class="slide-image"
              :src="slide.logoIcon.url"
              :alt="slide.logoAltTag || slide.logoIcon.title"/>
          </div>
        </div>
        <template v-slot:prevButton>
          <p :style="globalTheme.color">
            <carousel-chevron-left
              class="chevron-controls"
              :style="[globalTheme.fillBlack]"
            />
          </p>
        </template>
        <template v-slot:nextButton>
          <p :style="globalTheme.color">
            <carousel-chevron-right
              class="chevron-controls"
              :style="[globalTheme.fillBlack]"
            />
          </p>
        </template>
      </agile> 
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  import { VueAgile } from 'vue-agile'
  import CarouselChevronRight from '@/components/atoms/svg/CarouselChevronRight.vue'
  import CarouselChevronLeft from '@/components/atoms/svg/CarouselChevronLeft.vue'
  export default defineComponent({
    name: 'LogoSlider',
    components: {
      agile: VueAgile,
      CarouselChevronRight,
      CarouselChevronLeft
    },
    props: {
      section: {
        required: true,
        type: Object as () => Boilerplate
      }
    },
    data() {
      return {
        carouselConfig: {
          dots: false,
          centerMode: false,
          slidesToShow: 2,
          responsive: [
            {
              breakpoint: 479,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 959,
              settings: {
                slidesToShow: 5
              }
            },
            {
              breakpoint: 1099,
              settings: {
                slidesToShow: 6
              }
            }
          ]
        }
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
