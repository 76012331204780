<template>
  <div class="page body content">
    <div class="theloop">
      <section
        class="four-o-four"
        aria-label="page cannot be found">
        <div container>
          <div grid="row wrap">
            <div
              column="xs-12 s-8 +s-2 l-6 +l-3"
              class="_text-center">
              <app-inline-svg
                :src="section.imagePath"
                :title="section.imageAlt"
                class="_mbm"
              />
            </div>
            <div
              column="xs-12 s-12"
              class="_text-center">
              <h1
                class="_mbs">
                {{ section.title }}
              </h1>
              <div
                v-if="section.content"
                class="_p0"
                v-html="section.content"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import imagepath from '../assets/404.svg'
  export default defineComponent({
    data() {
      return {
        section: {
          title: '404 - Page not found',
          content: 'Sorry, it looks like this page doesn\'t exist.',
          imagePath: imagepath,
          imageAlt: 'pair of broken glasses'
        }
      }
    }
  })
</script>
