import { createStore } from 'vuex'

export default createStore({
  state: {
    primaryColor: null,
    secondaryColor: null,
    currentRoute: null,
    page: null,
    globals: null
  },
  mutations: {
    setPrimaryColor(state, color): void {
      state.primaryColor = color
    },
    setSecondaryColor(state, color): void {
      state.secondaryColor = color
    },
    setCurrentRoute(state, route): void {
      state.currentRoute = route
    },
    setCurrentGlobals(state, globals): void {
      state.globals = globals
    },
    setCurrentPage(state, page): void {
      state.page = page
      if (document && document.title && state.globals) {
        document.title = `${(state.globals as any).applicationTitle}: ${page.pageTitle || page.title}`
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
