<template>
  <section class="member-demo-module media-feature">
    <div
      class="gradient"
      :style="gradient"></div>
    <div
      container
      class="content">
      <div grid="row wrap top justify-center">
        <div
          v-if="section.title || section.content"
          column="xs-12 s-12 m-5 xl-4"
        >
          <div class="read-content">
            <img
              src="@/assets/images/svg/mag.svg"
              class="mag-lense"
              alt="magnifying lense"
            />
            <div>
              <h2
                v-if="section.title"
                :style="globalTheme.color"
                class="h2 _mt0"
              >
                {{ section.title }}
              </h2>
              <div v-if="section.content">
                <rich-text :html="section.content"></rich-text>
              </div>
            </div>
            <div
              v-if="section.buttonLink && section.buttonLink.href"
              class="cta-wrap"
            >
              <app-button-link
                :button="section.buttonLink"
                :style="globalTheme.button"
              />
            </div>
          </div>
        </div>
        <div
          column="xs-12 s-12 m-7 xl-8"
          class="tablet-up">
          <div class="featured-media">
            <VideoBlock
              v-if="hasVideo"
              :video="section.featuredVideo"
              :poster="section.featuredVideoPoster"
              :description="section.featuredVideoDescriptionTrack"
              :subtitles="section.featuredVideoSubtitleTrack"
              :embed="section.featuredEmbedUrl"
            />
            <img
              v-else-if="section.featuredVideoPoster"
              :src="section.featuredVideoPoster.url"
              :alt="section.featuredImageAlt"
            />
          </div>
        </div>
      </div>
    </div>
    <VideoBlock
      v-if="(section.featuredVideo || section.featuredEmbedUrl) && hasVideo"
      class="mobile until-tablet video"
      :video="section.featuredVideo"
      :poster="section.featuredVideoPoster"
      :description="section.featuredVideoDescriptionTrack"
      :subtitles="section.featuredVideoSubtitleTrack"
      :embed="section.featuredEmbedUrl"
    />
    <img
      v-else-if="section.featuredVideoPoster"
      class="mobile until-tablet mobile-media-block"
      :src="section.featuredVideoPoster.url"
      :alt="section.featuredImageAlt"
    />
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, ContentStackFile } from '@/types'
  import VideoBlock from '@/components/sections/MediaFeature/VideoBlock.vue'

  interface MediaFeature extends Boilerplate {
    gradientStart: { colorHex: string }[];
    gradientEnd: { colorHex: string }[];
    featuredVideo?: ContentStackFile;
    featuredEmbedUrl: {
      href?: string;
      title?: string;
    };
  }

  export default defineComponent({
    name: 'media-feature',
    components: {
      VideoBlock
    },
    props: {
      section: {
        required: true,
        type: Object as () => MediaFeature
      }
    },
    computed: {
      hasVideo(): boolean {
        return (!!this.section.featuredVideo || !!(this.section.featuredEmbedUrl && this.section.featuredEmbedUrl.href))
      },
      gradient(): { background: string } | null {
        const gradientStart = this.section.gradientStart[0]
          ? this.section.gradientStart[0].colorHex
          : null
        const gradientEnd = this.section.gradientEnd[0]
          ? this.section.gradientEnd[0].colorHex
          : null
        if (gradientStart && gradientEnd) {
          return {
            background: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`
          }
        } else {
          return null
        }
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
