<template>
  <section class="member-demo-module">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="section-header h2"
        >
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          class="copy-wrap">
          <rich-text
            :html="section.content"
            :theme-color-override="
              section.colorOptions
                ? section.colorOptions.themeColorOverride
                : null
            "></rich-text>
        </div>
      </div>
      <div
        v-if="section.buttonLink && section.buttonLink.href"
        class="cta-wrap center-cta _mtm">
        <app-button-link
          :button="section.buttonLink"
          :style="globalTheme.button"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  export default defineComponent({
    name: 'simple-rich-text',
    props: {
      section: {
        required: true,
        type: Object as () => Boilerplate
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
