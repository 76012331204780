<template>
  <section class="member-demo-module chart">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content _text-center"
      >
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="_mbs">
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          class="copy-wrap">
          <rich-text
            :html="section.content"
            class="not-themed"></rich-text>
        </div>
      </div>
      <div class="table-wrap">
        <table class="_mts">
          <thead class="table-head">
            <tr>
              <head-cell
                v-for="(column, i) in section.chartColumn"
                :id="`table-column-${i+1}-${uid}`"
                :key="i"
                :theme-color-override="column.themeColorOverride"
                :class="[{ 'current-column': isCurrentColumn(i) }, `columns-${setColSpan()}`, {'_text-left': column.alignTitleLeft}]"
              >
                <button
                  class="mobile"
                  :class="[{ 'current-column': isCurrentColumn(i) }]"
                  @click="setCurrentColum(i)"
                >
                  <span>{{ column.columnTitle }}</span>
                </button>
                <button
                  tabindex="-1"
                  class="desktop disabled"
                  :class="{'_text-left': column.alignTitleLeft}"
                >
                  <span>{{ column.columnTitle }}</span>
                </button>
              </head-cell>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              v-for="j in maxRows"
              :key="`row_${j}`">
              <template v-for="(column, k) in section.chartColumn">
                <template v-if="column.row[j - 1]">
                  <cell
                    :key="`mobile_column_${k}_row_${j}`"
                    class="mobile"
                    :class="{ 'current-column': isCurrentColumn(k) }"
                    :colspan="setColSpan()"
                    :cell="column.row[j - 1]"
                    :theme-color-override="column.themeColorOverride"
                    :headers="`table-column-${k+1}-${uid}`"
                  />
                  <cell
                    :key="`desktop_column_${k}_row_${j}`"
                    class="desktop"
                    :cell="column.row[j - 1]"
                    :theme-color-override="column.themeColorOverride"
                    :headers="`table-column-${k+1}-${uid}`"
                  />
                </template>
                <template v-else>
                  <td
                    :key="`mobile_column_${k}_row_${j}`"
                    class="mobile"
                    :class="{ 'current-column': isCurrentColumn(k) }"
                    :colspan="setColSpan()"
                    :headers="`table-column-${k+1}-${uid}`"
                  ></td>
                  <td
                    :key="`desktop_column_${k}_row_${j}`"
                    class="desktop"
                    :headers="`table-column-${k+1}-${uid}`"
                  ></td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'

  import { CellProps } from './Chart/Cell.vue'

  interface Column {
    themeColorOverride?: Record<string, unknown>[] | undefined;
    columnTitle?: string;
    alignTitleLeft?: boolean;
    row: CellProps[];
  }

  interface Chart extends Boilerplate {
    chartColumn: Column[];
  }

  import Cell from './Chart/Cell.vue'
  import HeadCell from './Chart/HeadCell.vue'
  import { v4 as uuidv4 } from 'uuid'

  export default defineComponent({
    name: 'Chart',
    components: { Cell, HeadCell },
    props: {
      section: {
        required: true,
        type: Object as () => Chart
      }
    },
    data() {
      return {
        currentColumn: 0,
        uid: uuidv4(),
      }
    },
    computed: {
      maxRows(): number {
        const masterArray = this.section.chartColumn
        const indexOfMaxRows = masterArray
          .map(a => a.row.length)
          .indexOf(Math.max(...masterArray.map(a => a.row.length)))
        return masterArray[indexOfMaxRows].row.length
      }
    },
    methods: {
      setCurrentColum(i: number): void {
        this.currentColumn = i
      },
      isCurrentColumn(i: number): boolean {
        return this.currentColumn === i
      },
      setColSpan(): number {
        return this.section.chartColumn.length
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
