<template>
  <svg
    aria-hidden="true"
    focusable="false"
    width="58px"
    height="64px"
    viewBox="0 0 58 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="lock-icon"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <g
        id="icon-/-lock-leaf"
        transform="translate(-3.000000, 0.000000)"
        fill="inherit"
      >
        <path
          id="Combined-Shape"
          d="M39.508863,51.8822803 C39.7814929,51.4748472 39.8331465,50.9585638 39.6461858,50.5047685 L36.0374929,41.7455874 C37.901052,40.460548 39.0462488,38.333178 39.0462488,36.0029732 C39.0462488,32.1533984 35.9152882,29.0219339 32.0669732,29.0219339 C28.2179024,29.0219339 25.0866898,32.1533984 25.0866898,36.0029732 C25.0866898,38.333178 26.2323906,40.460548 28.0959496,41.7455874 L24.4867528,50.5047685 C24.3002961,50.9585638 24.3519496,51.4748472 24.6245795,51.8822803 C24.8972094,52.2897134 25.3552882,52.5343748 25.8453669,52.5343748 L38.2873197,52.5343748 C38.7781543,52.5343748 39.2357291,52.2897134 39.508863,51.8822803 Z M57.7823748,17.6337134 C59.2022173,17.6337134 60.3539654,18.7849575 60.3539654,20.205052 L60.3539654,61.3507528 C60.3539654,62.7716031 59.2022173,63.9225953 57.7823748,63.9225953 L6.35081575,63.9225953 C4.93072126,63.9225953 3.77947717,62.7716031 3.77947717,61.3507528 L3.77947717,20.205052 C3.77947717,18.7849575 4.93072126,17.6337134 6.35081575,17.6337134 L57.7823748,17.6337134 Z M21.0452661,15.4295433 C19.6249197,15.4295433 18.4739276,14.2780472 18.4739276,12.8577008 C18.4739276,5.76806299 24.5713134,-3.90798505e-14 32.0668724,-3.90798505e-14 C39.5619276,-3.90798505e-14 45.6595654,5.76806299 45.6595654,12.8577008 C45.6595654,14.2780472 44.5083213,15.4295433 43.0879748,15.4295433 C41.6678803,15.4295433 40.5161323,14.2780472 40.5161323,12.8577008 C40.5161323,8.60422047 36.7257701,5.14267717 32.0668724,5.14267717 C27.4074709,5.14267717 23.6166047,8.60422047 23.6166047,12.8577008 C23.6166047,14.2780472 22.4656126,15.4295433 21.0452661,15.4295433 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
<style scoped>
.lock-icon {
  margin-right: 0.25rem;
  height: 16px;
  width: 16px;
  transform: translateY(-2px);
}
</style>
