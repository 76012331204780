// Contentstack content blocks dependant sections
// import ContactForm from '@/components/sections/ContactForm.vue'


import Resources from '@/components/sections/Resources.vue'
import SimpleRichText from '@/components/sections/SimpleRichText.vue'
import SmallCalloutsWithImage from '@/components/sections/SmallCalloutsWithImage.vue'
import FeaturedTextWithImages from '@/components/sections/FeaturedTextWithImages.vue'
import ContentWithVideo from '@/components/sections/ContentWithVideo.vue'
import ConstituentAction from '@/components/sections/ConstituentAction.vue'
import SmallFeature from '@/components/sections/SmallFeature.vue'
import SmallFeatureSingle from '@/components/sections/SmallFeatureSingle.vue'
import FaqSection from '@/components/sections/FAQSection.vue'
import SimpleTitle from '@/components/sections/SimpleTitle.vue'
import FeaturedLists from '@/components/sections/FeaturedLists.vue'
import IconWithText from '@/components/sections/IconWithText.vue'
import ContactCta from '@/components/sections/ContactCta.vue'
import Carousel from '@/components/sections/Carousel.vue'
import LogoSlider from '@/components/sections/LogoSlider.vue'
import ChoiceModals from '@/components/sections/ChoiceModals.vue'
import CalloutStrip from '@/components/sections/CalloutStrip.vue'
import HeroSimple from '@/components/sections/HeroSimple.vue'
import MediaFeature from '@/components/sections/MediaFeature.vue'
import ToTopButton from '@/components/sections/ToTopButton.vue'
import Chart from '@/components/sections/Chart.vue'
import ContactForm from '@/components/sections/ContactForm.vue'





export default {
  Resources,
  SmallCalloutsWithImage,
  SimpleRichText,
  FeaturedTextWithImages,
  ContentWithVideo,
  ConstituentAction,
  SmallFeature,
  SmallFeatureSingle,
  FaqSection,
  SimpleTitle,
  FeaturedLists,
  IconWithText,
  ContactCta,
  Carousel,
  LogoSlider,
  ChoiceModals,
  CalloutStrip,
  HeroSimple,
  MediaFeature,
  ToTopButton,
  Chart,
  ContactForm
}
