<template>
  <section
    :aria-label="section.title"
    class="constituent-action"
  >
    <div container>
      <div class="flex-wrap">
        <h2
          class="h2 section-header"
          :style="globalTheme.color">{{section.title}}</h2>
        <div
          class="content"
          v-html="section.content"></div>
        <app-button-link
          :button="section.buttonLink" 
          :style="globalTheme.button"/>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ConstituentAction } from '@/types'
  export default defineComponent({
    name: 'constituentAction' as string,
    props: {
      section: {
        required: true,
        type: Object as () => ConstituentAction
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
