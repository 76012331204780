<template>
  <section class="member-demo-module carousel">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="section-header h2"
        >
          {{ section.title }}
        </h2>
      </div>
       <agile :options="carouselConfig">
        <div
          v-for="(slide, i) in section.slides"
          :key="i"
          class="slide">
          <div
            class="slide-content"
            :style="globalTheme.borderColor">
            <template v-if="!section.removeCarouselIcons">
              <app-inline-svg
                v-if="slide.svgIconOverride"
                :style="[globalTheme.color, globalTheme.fill]"
                :src="slide.svgIconOverride.url"
              />
              <quote-icon
                v-else
                :style="[globalTheme.color, globalTheme.fill]"
              />
            </template>
            <rich-text
              v-if="slide.content"
              :html="slide.content"></rich-text>
            <p
              v-if="slide.author"
              class="author">{{ slide.author }}</p>
          </div>
        </div>
        <template v-slot:prevButton>
          <p :style="globalTheme.color">
            <carousel-chevron-left
              class="chevron-controls"
              :style="[globalTheme.color, globalTheme.fill]"
            />
          </p>
        </template>
        <template v-slot:nextButton>
          <p :style="globalTheme.color">
            <carousel-chevron-right
              class="chevron-controls"
              :style="[globalTheme.color, globalTheme.fill]"
            />
          </p>
        </template>
      </agile> 
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  import { VueAgile } from 'vue-agile'
  import Quote from '@/components/atoms/svg/Quote.vue'
  import CarouselChevronRight from '@/components/atoms/svg/CarouselChevronRight.vue'
  import CarouselChevronLeft from '@/components/atoms/svg/CarouselChevronLeft.vue'
  export default defineComponent({
    name: 'Carousel',
    components: {
      agile: VueAgile,
      QuoteIcon: Quote,
      CarouselChevronRight,
      CarouselChevronLeft
    },
    props: {
      section: {
        required: true,
        type: Object as () => Boilerplate
      }
    },
    data() {
      return {
        carouselConfig: {
          dots: false,
          centerMode: true,
          slidesToShow: 1,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3
              }
            }
          ]
        }
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
