<template>
  <localized-router-link
    v-if="choice && choice.link"
    class="choice"
    :to="choice.link.href"
    :style="globalTheme.borderColor">
    <app-inline-svg
      v-if="choice.icon"
      :src="choice.icon.url" 
      :style="globalTheme.fillSoft"
      class="svg"/>
    <div class="content">
      <p :style="globalTheme.colorSoft">
        {{choice.link.title}}
      </p>
    </div>
    <div
      class="background"
      :style="globalTheme.backgroundColor"></div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  interface ChoiceModal {
    themeColorOverride: Record<string, unknown>[]|undefined
  }
  export default defineComponent({
    name: 'SingleChoiceModal',
    props: {
      choice: {
        required: true,
        type: Object as () => ChoiceModal
      }
    },
    computed: {
      colorOverride(): string | undefined |unknown {
        const colorOverrideObject = this.choice.themeColorOverride && this.choice.themeColorOverride.length ? this.choice.themeColorOverride[0] : {}
        return colorOverrideObject.colorHex as string|undefined
      },
    }
  })
</script>
