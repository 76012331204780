<template>
  <div class="logo-container">
    <localized-router-link to="/" >
      <template v-if="insuranceLogo">
        <div
          :style="`background-image: url(${insuranceLogo})`"
          class="insurance-logo-wrap">
          <img
            :src="insuranceLogo"
            :alt="`${clientId} home page`"
            class="insurance-logo"
          >
        </div>
      </template>
      <template v-else>
        <img
          :src="logoImage"
          alt="EyeMed home page"
          class="logo"
        >
      </template>
    </localized-router-link>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import logoImage from '@/assets/images/svg/eyemed-logo.svg'
  export default defineComponent({
    name: 'logo' as string,
    props: {
      insurance: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        clientId: this.$route.params.clientID,
        logoImage:logoImage
      }
    },
    computed: {
      insuranceLogo(): string | undefined {
        const microsite = this.$store.state.globals
        return microsite && microsite.logo ? microsite.logo.url : undefined
      } 
    },
  })
</script>
