
<template>
  <svg
    width="14"
    height="25"
    viewBox="0 0 14 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.03505 23.918C1.32802 24.2435 1.68609 24.4062 2.10927 24.4062C2.53245 24.4062 2.90679 24.2435 3.23232 23.918L13.3397 13.8105C13.5025 13.6478 13.6164 13.4688 13.6815 13.2734C13.7792 13.0781 13.828 12.8828 13.828 12.6875C13.828 12.4922 13.7792 12.2969 13.6815 12.1016C13.6164 11.9062 13.5025 11.7272 13.3397 11.5645L3.23232 1.45703C2.90679 1.13151 2.53245 0.96875 2.10927 0.96875C1.68609 0.96875 1.32802 1.13151 1.03505 1.45703C0.709529 1.75 0.546768 2.12435 0.546768 2.58008C0.546768 3.00326 0.709529 3.36133 1.03505 3.6543L10.0683 12.6875L1.03505 21.7207C0.709529 22.0137 0.546768 22.388 0.546768 22.8438C0.546768 23.2669 0.709529 23.625 1.03505 23.918Z"
      fill="currentColor"/>
  </svg>
</template>
